.art-flow__row-description {
  text-align: center;
  font-size: 2em;
  font-style: italic; }

.art-flow__row-divider {
  height: 1px;
  max-width: 40rem;
  background-color: $text;
  margin: 2rem auto 4rem; }
