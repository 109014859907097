.menu-bar {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    left: 8rem;
    right: 8rem;
    bottom: 0;
    height: 1px;
    margin: 0 auto;
    background-color: $grey-light; } }
