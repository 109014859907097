.page-header {
  margin: 1rem auto 4rem;
  font-family: Pacifico, serif;
  text-align: center;
  color: #000; }

.page-header__suptitle {
  font-size: 2rem;
  margin-bottom: 1rem; }

.page-header__title {
  font-size: 4rem;
  margin: 2rem 0; }

.page-header__divider {
  height: 0.3rem;
  border-radius: 0.15rem;
  background-color: #000;
  max-width: 40rem;
  margin: 0 auto 2rem; }
